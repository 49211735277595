import React, { useState } from "react"
import styled from "styled-components"
import backgroundImage from "gatsby-background-image"
import Img from "gatsby-image"

import VirtualTourModal from "../modals/virtualTourModal"

const VirtualTourFloorPlan = ({
  floorplan,
  floorplan_media,
  floorplan_placeholder_image,
  virtual_tour,
  virtual_tour_media,
  virtual_tour_placeholder_image,
}) => {
  const [floorplanModalOpen, setFloorplanModalOpen] = useState<boolean>(false)
  const [tourModalOpen, setTourModalOpen] = useState<boolean>(false)

  return (
    <Container>
      <MediaContainer onClick={() => setTourModalOpen(!tourModalOpen)}>
        {virtual_tour ? <Title>{virtual_tour.text}</Title> : null}
        {virtual_tour_media ? (
          <StyledImage
            // @ts-ignore
            fluid={virtual_tour_placeholder_image.fluid}
          />
        ) : null}
        <VirtualTourModal
          type="virtual tour"
          isOpen={tourModalOpen}
          setIsOpen={setTourModalOpen}
          media={virtual_tour_media.url}
        />
      </MediaContainer>
      <MediaContainer
        onClick={() => setFloorplanModalOpen(!floorplanModalOpen)}
      >
        {floorplan ? <Title>{floorplan.text}</Title> : null}
        {floorplan_media ? (
          <Image fluid={floorplan_placeholder_image.fluid} alt="floorplan">
            <Text>View Floorplan</Text>
          </Image>
        ) : null}
        <VirtualTourModal
          type="floorplan"
          isOpen={floorplanModalOpen}
          setIsOpen={setFloorplanModalOpen}
          media={floorplan_placeholder_image.fluid}
        />
      </MediaContainer>
    </Container>
  )
}

export default VirtualTourFloorPlan

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0 40px;
  justify-content: center;
  width: 80%;
  margin: 0 auto;
  padding: 24px 0;
  @media (max-width: 1024px) {
    grid-template-columns: 1fr;
    grid-gap: 32px 0;
  }
`
const MediaContainer = styled.div`
  cursor: pointer;
`
export const Title = styled.h3`
  font-family: "Canela Light", sans-serif;
  color: var(--primary-text);
  font-size: 2rem;
`
const Image = styled(backgroundImage)`
  position: relative;
  height: 350px;
  width: 100%;

  @media (max-width: 1024px) {
    height: 300px;
  }
  :hover {
    background-size: cover;
    opacity: 1 !important; // (*)
    background: rgba(0, 0, 0, 0.5);
    p {
      opacity: 0.9;
      transition: all 0.3s ease-in;
      z-index: 5;
    }
  }
`
const StyledImage = styled(Img)`
  position: relative;
  height: 350px;
  width: 100%;

  @media (max-width: 1024px) {
    height: 300px;
  }
  //:hover {
  //  background-size: cover;
  //  opacity: 1 !important; // (*)
  //  background: rgba(0, 0, 0, 0.5);
  //  p {
  //    opacity: 0.9;
  //    transition: all 0.3s ease-in;
  //    z-index: 5;
  //  }
  //}
`
const Text = styled.p`
  font-family: "Moderat Regular", sans-serif;
  color: white;
  position: absolute;
  font-size: 20px;
  left: 50%;
  top: 50%;
  transform: translateY(-50%);
  transform: translateX(-50%);
  opacity: 0;
`

const Anchor = styled.a`
  //position: relative;
  text-decoration: none;
`
