import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"

const FeatureItem = ({ feature_icon, feature_type, items }) => {
  return (
    <Container>
      <IconContainer>
        <Img
          style={{ justifySelf: "flex-end" }}
          fixed={feature_icon.fixed}
          alt={feature_icon.alt}
        />
      </IconContainer>
      <div>
        <Title>{feature_type.text}</Title>
        <FeaturesContainer>
          {items.map(({ feature }, i) => (
            <Feature key={i}>{feature.text}</Feature>
          ))}
        </FeaturesContainer>
      </div>
    </Container>
  )
}

export default FeatureItem

const Container = styled.div`
  display: grid;
  grid-template-columns: 50px auto;
  grid-gap: 0 16px;
`

const Title = styled.span`
  color: var(--primary-text);
  font-family: "Moderat Bold", sans-serif;
`

const FeaturesContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`
const Feature = styled.span`
  padding: 8px 4px 8px 0;
  color: var(--primary-text);
  font-family: "Moderat Regular", sans-serif;
  max-width: 300px;
`

const IconContainer = styled.div`
  .gatsby-image-wrapper {
    max-width: 36px;
    max-height: 36px;
  }
`
