import React from "react"
import Img from "gatsby-image"
import styled from "styled-components"

import { CloseIcon } from "./ownershipModalButton"

const VirtualTourModal = ({ type, isOpen, setIsOpen, media }) => {
  return (
    <Overlay open={isOpen}>
      <Container>
        <CloseIcon onClick={() => setIsOpen(!isOpen)} />
        {type === "floorplan" ? (
          <Img
            fluid={media}
            imgStyle={{
              objectFit: "fill",
            }}
            alt="floorplan"
          />
        ) : (
          <Iframe src={media} />
        )}
      </Container>
    </Overlay>
  )
}

export default VirtualTourModal

export const Overlay = styled.div<{ open: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.5);
  display: ${({ open }) => (open ? "flex" : "none")};
  justify-content: center;
  align-items: center;
  z-index: 2;
`
export const Container = styled.div`
  position: relative;
  .gatsby-image-wrapper {
    max-height: 80vh;
    width: 80vw;
  }
`

const Iframe = styled.iframe`
  border: none;
  height: 80vh;
  width: 80vw;
`
