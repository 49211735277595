import React, { useEffect, useState } from "react"
import styled from "styled-components"
import Image from "gatsby-image"
import { RiFullscreenFill } from "react-icons/ri"
import ImageModal from "../modals/imageModal"

const GalleryItem = ({ big_image, small_image_1, small_image_2, images }) => {
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const [image, setImage] = useState(undefined)

  return (
    <>
      <Container>
        <BigImageContainer
          area="big"
          onClick={() => {
            setImage(big_image)
            setModalOpen(!modalOpen)
          }}
        >
          <Image fluid={big_image.fluid} alt={big_image.alt || "room"} />
          <Icon type="big" />
        </BigImageContainer>
        <SmallImageContainer
          area="small1"
          onClick={() => {
            setImage(small_image_1)
            setModalOpen(!modalOpen)
          }}
        >
          <Image fluid={small_image_1.fluid} alt={big_image.alt || "room"} />
          <Icon type="small" />
        </SmallImageContainer>
        <SmallImageContainer
          area="small2"
          onClick={() => {
            setImage(small_image_2)
            setModalOpen(!modalOpen)
          }}
        >
          <Image fluid={small_image_2.fluid} alt={big_image.alt || "room"} />
          <Icon type="small" />
        </SmallImageContainer>
        <ImageModal
          image={image}
          images={images}
          isOpen={modalOpen}
          setIsOpen={setModalOpen}
        />
      </Container>
    </>
  )
}

export default GalleryItem

const Container = styled.div`
  :last-child {
    padding-right: 194px;
  }
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-template-areas: "big small1" "big small2";
  grid-gap: 40px;
  padding: 0 40px 0 0;

  @media (max-width: 800px) {
    padding: 0 0 40px 0;
    grid-template-columns: 1fr;
    grid-template-areas: "big" "small1" "small2";
    :last-child {
      padding-right: 0;
    }
  }
`

const BigImageContainer = styled.div<{ area: string }>`
  grid-area: ${({ area }) => area};
  .gatsby-image-wrapper {
    height: 440px;
    max-height: 440px;
    min-width: 600px;
    @media (max-width: 1150px) {
      min-width: 450px;
    }
    @media (max-width: 950px) {
      height: 390px;
      min-width: 350px;
    }
    @media (max-width: 800px) {
      height: 250px;
      width: 100%;
    }
  }
  position: relative;
  cursor: pointer;
  > svg {
    opacity: 0;
  }
  :hover {
    box-shadow: var(--box-shadow);
    > svg {
      opacity: 1;
    }
  }
  transition: all 0.3s ease;
`

const SmallImageContainer = styled.div<{ area: string }>`
  grid-area: ${({ area }) => area};
  .gatsby-image-wrapper {
    height: 200px;
    min-width: 200px;
    @media (max-width: 950px) {
      height: 175px;
    }
    @media (max-width: 800px) {
      height: 250px;
      width: 100%;
    }
  }
  position: relative;
  cursor: pointer;
  > svg {
    opacity: 0;
  }
  :hover {
    box-shadow: var(--box-shadow);
    > svg {
      opacity: 1;
    }
  }
  transition: all 0.3s ease;
`

const Icon = styled(RiFullscreenFill)<{ type: string }>`
  position: absolute;
  color: white;
  bottom: ${({ type }) => (type === "big" ? "24px" : "8px")};
  right: ${({ type }) => (type === "big" ? "24px" : "8px")};
  font-size: ${({ type }) => (type === "big" ? "28px" : "16px")};
`
