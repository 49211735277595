import React, { useRef, useState } from "react"
import styled from "styled-components"

import leftArrow from "../../assets/icons/arrowLeft.png"
import rightArrow from "../../assets/icons/arrowRight.png"
import GalleryItem from "./galleryItem"
import useWindowSize from "../../hooks/utils/useWindowSize"

const Gallery = ({ gallery }) => {
  const [isScrolling, setIsScrolling] = useState<boolean>(false)
  const { width } = useWindowSize()
  const galleryRef = useRef(null)

  const images = gallery
    .map(({ big_image, small_image_1, small_image_2 }) => [
      big_image.fluid,
      small_image_1.fluid,
      small_image_2.fluid,
    ])
    .flat()
    .filter(image => image !== null)

  let offset
  // matches media queries
  if (width >= 1150) {
    offset = 880
  } else if (width < 1150 && width > 950) {
    offset = 730
  } else if (width <= 950) {
    offset = 630
  }
  const scroll = scrollOffset => {
    galleryRef.current.scrollLeft += scrollOffset
    setIsScrolling(true)
  }
  return (
    <OuterWrapper>
      <Icon
        onClick={() => scroll(-offset)}
        position="left"
        src={leftArrow}
        alt="arrow right"
        show={isScrolling}
      />
      <Wrapper ref={galleryRef}>
        {gallery.map((galleryItem, i) => (
          <GalleryItem key={i} {...galleryItem} images={images} />
        ))}
      </Wrapper>
      <Icon
        onClick={() => scroll(offset)}
        position="right"
        src={rightArrow}
        alt="arrow right"
        show
      />
    </OuterWrapper>
  )
}

export default Gallery

const OuterWrapper = styled.div`
  position: relative;
  margin: 0 auto;
  width: 990px;
  min-width: 800px;
  @media (max-width: 1150px) {
    width: 75%;
    min-width: 800px;
  }
  @media (max-width: 950px) {
    width: 80%;
    min-width: 750px;
  }
  @media (max-width: 800px) {
    flex-direction: column;
    min-width: unset;
  }
`

const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
  display: flex;
  scroll-behavior: smooth;
  & > * {
    flex-grow: 1;
    padding: 32px 40px 32px 0;
  }
  @media (max-width: 800px) {
    overflow: scroll;
    flex-direction: column;
    height: 800px;
  }
`

const Icon = styled.img<{ position?: string; show?: boolean }>`
  z-index: 1;
  position: absolute;
  top: calc(50% - 30px);
  cursor: pointer;
  height: 40px;
  transition: margin 0.3s ease-in-out;
  :hover {
    ${({ position }) =>
      position === "left" ? "margin-left: -4px;" : "margin-right: -4px;"}
  }
  @media (max-width: 800px) {
    display: none;
  }
  display: ${({ show }) => (show ? "initial" : "none")};
  ${({ position }) => (position === "left" ? "left: 24px; " : "right: 24px;")}
`
