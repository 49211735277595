import styled from "styled-components"

export const SectionTitle = styled.h1<{
  width?: string
  color?: string
  align?: string
}>`
  color: ${({ color }) => color || "var(--primary-text)"};
  text-align: ${({ align }) => align || "center"};
  font-family: "Canela Light", sans-serif;
  font-size: 3.5rem;
  margin: 1rem 0 1rem 0;
  font-weight: 100;
  max-width: ${({ width }) => width};
  @media (max-width: 1024px) {
  }
  @media (max-width: 776px) {
    text-align: center;
  }
`

export const Tagline = styled.p`
  color: ${({ color }) => color || "var(--primary-text)"};
  margin: 1rem auto;
  font-family: "Moderat Regular", sans-serif;
  line-height: 20pt;
  //padding: 0 16px 0 0;
  text-align: center;
  font-size: 1.1rem;
  max-width: 380px;

  @media (max-width: 776px) {
    padding: 0 16px;
  }
`

export const NewsArticleHeading = styled.h2`
  margin-top: 0;
  font-size: 1.5rem;
  color: ${({ color }) => color || "var(--primary-text)"};
  font-family: "Moderat Medium", sans-serif;
`

export const ArticleHeading = styled.h3`
  margin: 0 0 1rem 0;
  color: ${({ color }) => color || "var(--primary-text)"};
  font-family: "Moderat Bold", sans-serif;
`
