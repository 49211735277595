import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import Img from "gatsby-image"

import { Title } from "./virtualTourFloorplan"

const CheckAvailability = props => {
  return (
    <Container>
      <GoToItem
        align="left"
        icon={props.primary.arrow_left}
        {...props.items[0]}
      />
      <SectionHeader>
        <Anchor
          href={props.primary.book_link.url}
          target="_blank"
          rel="noreferrer noopener"
        >
          <Title>{props.primary.section_title.text}</Title>
          <Title>{props.primary.book_text.text}</Title>
        </Anchor>
      </SectionHeader>
      <GoToItem
        align="right"
        icon={props.primary.arrow_right}
        {...props.items[1]}
      />
    </Container>
  )
}

export default CheckAvailability

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: 80%;
  margin: 0 auto;
  padding: 40px 0;
  grid-gap: 0 16px;
  @media (max-width: 1090px) {
    grid-template-columns: max-content;
    grid-gap: 40px 0;
    justify-content: center;
    justify-items: center;
  }
`
const SectionHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  h3 {
    margin: 0;
    padding: 8px;
  }
`
const GoToItem = props => {
  const { room_page, room_category, room_type, icon, align } = props
  return (
    <StyledLink to={room_page.url}>
      <ItemContainer align={align}>
        <ItemHeading
          style={{ justifySelf: align === "left" ? "left" : "right" }}
        >
          <RoomCategory>{room_category.text}</RoomCategory>
          <RoomType>{room_type.text}</RoomType>
        </ItemHeading>

        <ImageContainer>
          {room_page.document ? (
            <Img fluid={room_page.document.data.gallery[0].big_image.fluid} />
          ) : null}
        </ImageContainer>
        <ArrowContainer align={align}>
          <Img style={{ boxShadow: "none" }} fixed={icon.fixed} alt="go to" />
        </ArrowContainer>
      </ItemContainer>
    </StyledLink>
  )
}
const ItemContainer = styled.div<{ align: string }>`
  display: flex;
  justify-self: ${({ align }) => (align === "left" ? "left" : "right")};
  flex-direction: column;
  cursor: pointer;
  padding: 8px;
  transition: all 0.2s ease-in-out;
  > div :first-child {
    text-align: ${({ align }) => (align === "left" ? "right" : "left")};
  }
  :hover {
    transition: all 0.2s ease-in-out;
    .gatsby-image-wrapper {
      transform: scale(1.1);
      transition: all 200ms ease-in-out;
    }
    > div :last-child {
      ${({ align }) =>
        align === "left" ? "margin-left: 0;" : "margin-right: 0;"};
    }
  }
  @media (max-width: 780px) {
    justify-items: center;
    justify-self: unset;
  }
`
const ImageContainer = styled.div`
  width: 100%;
  overflow: hidden;
  .gatsby-image-wrapper {
    transition: all 200ms ease-in-out;
    min-width: 400px;
    height: 300px;
    @media (max-width: 1350px) {
      min-width: 300px;
      height: 200px;
    }
    @media (max-width: 1090px) {
      min-width: 380px;
      height: 300px;
    }
  }
`

const ItemHeading = styled.div`
  width: 100%;
  padding: 16px 0;
`
const RoomCategory = styled.p`
  color: var(--primary-text);
  font-size: 0.9rem;
  font-family: "Moderat Regular", sans-serif;
`
const RoomType = styled.p`
  color: var(--primary-text);
  font-family: "Moderat Medium", sans-serif;
`

const Anchor = styled.a`
  text-decoration: none;
  border-bottom: 1px solid var(--primary-text);
  cursor: pointer;
  margin: 0;
  transition: margin 0.3s ease-out;
  :hover {
    margin-top: 8px;
    transition: margin-top 0.3s ease-in;
  }
`

const ArrowContainer = styled.div<{ align: string }>`
  align-self: ${({ align }) => (align === "left" ? "flex-start" : "flex-end")};
  transition: all 0.2s ease-in-out;
  ${({ align }) =>
    align === "left" ? "margin-left: 16px;" : "margin-right: 16px;"};
  padding: 8px 0;
  .gatsby-image-wrapper {
    max-height: 10px;
    max-width: 75px;
  }
`

const StyledLink = styled(Link)`
  text-decoration: none;
`
