import React from "react"
import styled from "styled-components"
import { withPreview } from "gatsby-source-prismic"
import { graphql } from "gatsby"

import PageHeader from "../pageHeader"
import Gallery from "../bedroom/gallery"
import { Tagline } from "../atoms/headings"
import FeatureItem from "../bedroom/featureItem"
import VirtualTourFloorPlan from "../bedroom/virtualTourFloorplan"
import CheckAvailability from "../bedroom/checkAvailability"
import Layout from "../layout/layout"
import SEO from "../seo"
import PreviewWarning from "../atoms/userFeedback/PreviewWarning"

const RoomPage = ({ data }) => {
  const roomData = data.prismicRoom.data
  const features = roomData.body.filter(
    slice => slice.slice_type === "room_features"
  )
  const bookData = roomData.body.filter(slice => slice.slice_type === "book")
  const stayHereData = roomData.body.find(
    slice => slice.slice_type === "want_to_stay_here"
  )
  const virtualTourData = roomData.body.find(
    slice => slice.slice_type === "virtual_tour"
  )
  const availabilityData = roomData.body.find(
    slice => slice.slice_type === "check_availability"
  )
  return (
    <Layout bookBannerData={bookData.primary} stayHereData={stayHereData}>
      <SEO title={data.prismicRoom.uid} />
      <Container>
        <PageHeader
          title={roomData.title}
          tagline={roomData.tagline}
          text={roomData.text}
        />
        {!!roomData.gallery[0].big_image.fluid ? (
          <Gallery gallery={roomData.gallery} />
        ) : (
          <PreviewWarning section={"Gallery"} />
        )}
        {roomData.details_title.text.length !== 0 ? (
          <DetailsHeader>
            <div
              dangerouslySetInnerHTML={{ __html: roomData.details_title.html }}
            />
            <Tagline>{roomData.details_tagline.text}</Tagline>
          </DetailsHeader>
        ) : (
          <PreviewWarning section={"Details Title/Tagline"} />
        )}
        {features.length !== 0 ? (
          <FeaturesContainer>
            {features.map((feature, i) => (
              <FeatureItem key={i} {...feature.primary} items={feature.items} />
            ))}
          </FeaturesContainer>
        ) : (
          <PreviewWarning section="Room Features" />
        )}
        {virtualTourData ? (
          <VirtualTourFloorPlan {...virtualTourData.primary} />
        ) : (
          <PreviewWarning section={"Virtual Tour"} />
        )}
        {availabilityData ? (
          <CheckAvailability {...availabilityData} />
        ) : (
          <PreviewWarning section={"Availability"} />
        )}
      </Container>
    </Layout>
  )
}

export default withPreview(RoomPage)

const Container = styled.div`
  padding: 40px 0;
`

const DetailsHeader = styled.div`
  padding: 80px 0 40px 0;
  color: var(--primary-text);
  h2 {
    font-family: "Canela Light", sans-serif;
    font-size: 3rem;
    text-align: center;
  }
`
const FeaturesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 40px 40px;
  width: 60%;
  margin: 0 auto 40px auto;
  @media (max-width: 1200px) {
    width: 80%;
  }
  @media (max-width: 850px) {
    grid-template-columns: 1fr 1fr;
    width: 80%;
  }
  @media (max-width: 650px) {
    grid-template-columns: max-content;
    width: 80%;
    justify-content: center;
  }
`

export const query = graphql`
  query Room($uid: String!) {
    prismicRoom(uid: { eq: $uid }) {
      _previewable
      uid
      data {
        title {
          html
        }
        text {
          text
        }
        tagline {
          text
        }
        room_type
        room_category
        gallery {
          big_image {
            fluid {
              aspectRatio
              sizes
              src
              srcSet
            }
          }
          small_image_1 {
            fluid {
              aspectRatio
              src
              srcSet
              sizes
            }
          }
          small_image_2 {
            fluid {
              aspectRatio
              sizes
              src
              srcSet
            }
          }
        }
        details_title {
          raw
          text
          html
        }
        details_tagline {
          raw
          text
        }
        body {
          ... on PrismicRoomBodyRoomFeatures {
            id
            slice_type
            primary {
              feature_icon {
                fixed(width: 36) {
                  src
                  width
                  height
                  srcSet
                }
                alt
              }
              feature_type {
                text
              }
            }
            items {
              feature {
                text
              }
            }
          }
          ... on PrismicRoomBodyWantToStayHere {
            id
            slice_type
            primary {
              background_image {
                alt
                fluid {
                  aspectRatio
                  sizes
                  src
                  srcSet
                }
              }
              section_text {
                html
                text
                raw
              }
              section_title {
                raw
                html
              }
            }
            items {
              button_link {
                link_type
                url
              }
              button_text {
                text
              }
            }
          }
          ... on PrismicRoomBodyVirtualTour {
            id
            slice_type
            primary {
              floorplan {
                text
              }
              floorplan_media {
                url
              }
              floorplan_placeholder_image {
                fluid {
                  aspectRatio
                  sizes
                  src
                  srcSet
                }
              }
              virtual_tour {
                text
              }
              virtual_tour_media {
                url
              }
              virtual_tour_placeholder_image {
                alt
                fluid {
                  aspectRatio
                  sizes
                  src
                  srcSet
                }
              }
            }
          }
          ... on PrismicRoomBodyBook {
            id
            primary {
              button_text
              link {
                uid
              }
              text {
                raw
                text
              }
            }
            slice_type
          }
          ... on PrismicRoomBodyCheckAvailability {
            id
            slice_type
            items {
              room_category {
                text
              }
              room_type {
                text
              }
              room_page {
                document {
                  ... on PrismicRoom {
                    id
                    data {
                      gallery {
                        big_image {
                          fluid {
                            aspectRatio
                            sizes
                            src
                            srcSet
                          }
                        }
                      }
                    }
                  }
                }
                url
                link_type
              }
              room_type_image {
                fluid {
                  aspectRatio
                  sizes
                  src
                  srcSet
                }
                fixed(height: 131, width: 186) {
                  base64
                  height
                  src
                  srcSet
                  width
                }
                alt
              }
            }
            primary {
              arrow_left {
                alt
                fixed(height: 10, width: 75) {
                  base64
                  height
                  src
                  srcSet
                  width
                }
              }
              arrow_right {
                alt
                fixed(height: 10, width: 75) {
                  base64
                  height
                  src
                  srcSet
                  width
                }
              }
              book_link {
                url
              }
              book_text {
                text
              }
              section_title {
                text
              }
            }
          }
        }
      }
    }
  }
`
